<template>
  <div>
    <breadcrumb :items="breadcrumbs" />
    <hero>
      Categories
      <router-link 
        slot="right" 
        to="/categories/new"
        class="button"
      >
        Create Category
      </router-link>
    </hero>
    <section class="section is-main-section">
      <card
        class="has-table has-mobile-sort-spaced"
        title="Categories"
        icon="sitemap"
      >
        <Table 
          :data="categories"
          :default-sort="['distribution_id', 'desc']"
        >
          <template #columns>
            <b-table-column
              v-slot="props"
              label="ID"
              field="distribution_id"
              width="90"
              sortable
              searchable
            >
              {{ props.row.distribution_id }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Title"
              field="title"
              sortable
              searchable
            >
              {{ props.row.title }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              custom-key="actions"
              cell-class="is-actions-cell"
            >
              <div class="buttons is-right">
                <router-link 
                  :to="{ name: 'CategoriesEdit', params: { category: props.row.distribution_id } }" 
                  class="button is-small is-primary"
                >
                  <b-icon 
                    icon="pencil-outline" 
                    size="is-small"
                  />
                </router-link>
              </div>
            </b-table-column>
          </template>
        </Table>
      </card>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hero from "@/components/Hero";
import Card from "@/components/Card";
import Table from "@/components/Table";

export default {
  name: 'Categories',

  components: {
    Breadcrumb,
    Hero,
    Card,
    Table,
  },

  data() {
    return {
      breadcrumbs: [
        'Admin', 
        'Categories',
      ],
    };
  },

  computed: {
     ...mapGetters('data', ['categories']),
  },
};
</script>
